<template>
    <v-card
        v-if="clients.length > 0"
        flat
        @click="openQuote()"
        :style="`border-radius: 10px;`"
        outlined
        class="ma-0 pa-0"
    >
        <div class="pa-0 ma-0">
            <v-row
                class="d-flex flex-wrap font-weight-bold mx-2 mt-2 mb-0 pa-0"
            >
                <v-col class="pa-0 ma-0 d-flex" cols="10">
                    <p
                        v-if="quote.client || client"
                        :class="colorClass"
                        style="font-weight: bold !important"
                    >
                        {{ quote.client ? quote.client.name : client.name }}
                    </p>
                    <v-icon
                        v-if="collaborators == 0 || !quote.estimatorId"
                        class="ml-2 mt-n4"
                        color="red"
                        >mdi-account-multiple-remove</v-icon
                    >
                </v-col>

                <v-col class="d-flex pa-0 ma-0 justify-end" cols="2">
                    <v-row class="d-flex mt-1 justify-end">
                        <div>
                            <v-btn
                                icon
                                small
                                color="grey lighten-3"
                                @click.stop="openFollowUp"
                            >
                                <v-icon :color="color">mdi-chat-outline</v-icon>
                            </v-btn>
                        </div>
                        <div>
                            <v-menu rounded offset-y>
                                <template v-slot:activator="{ attrs, on }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        color="grey lighten-3"
                                        depressed
                                    >
                                        <v-icon :color="color"
                                            >mdi-dots-vertical</v-icon
                                        >
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item
                                        v-if="createHistory"
                                        @click.stop="archiveDialog"
                                    >
                                        <v-list-item-title>
                                            Archive
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="quote.status == 'OPEN'"
                                        @click.stop="quoteApproval"
                                    >
                                        <v-list-item-title>
                                            For Approval
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="
                                            (quote.status == 'SENT' ||
                                                quote.status == 'APPROVAL') &&
                                                createHistory
                                        "
                                        @click.stop="rejectedDialog"
                                    >
                                        <v-list-item-title>
                                            Lost
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="
                                            quote.status == 'SENT' ||
                                                quote.status == 'APPROVAL'
                                        "
                                        @click.stop="reOpenQuote"
                                    >
                                        <v-list-item-title>
                                            Modify
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="
                                            quote.status == 'SENT' &&
                                                createHistory
                                        "
                                        @click="quoteAwarded"
                                    >
                                        <v-list-item-title>
                                            Awarded
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </v-row>
                </v-col>
            </v-row>
            <v-row
                class="d-flex flex-wrap font-weight-regular mt-n3 mb-0 mx-2 pa-0"
            >
                <v-col class="pa-0 ma-0" cols="12">
                    <p class="ma-0 pa-0">
                        ({{ quote.number }})
                        {{ quote.name }}
                    </p>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                    <v-list-item-subtitle class="mt-0">
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <span v-on="on" style="cursor: pointer;">
                                    {{ quote.updatedOn }}
                                </span>
                            </template>
                            <span>
                                Due date:
                                {{
                                    quote.requestDate
                                        ? formatDate(
                                              quote.requestDate._seconds,
                                              quote.requestDate._nanoseconds
                                          )
                                        : ''
                                }}
                                <br />
                                Promise date:
                                {{
                                    quote.promiseDate
                                        ? formatDate(
                                              quote.promiseDate._seconds,
                                              quote.promiseDate._nanoseconds
                                          )
                                        : ''
                                }}
                            </span>
                        </v-tooltip>
                    </v-list-item-subtitle>
                </v-col>
                <v-col
                    class="d-flex justify-end mb-2 mt-0 mr-2 ml-0 pa-0"
                    cols="12"
                >
                    <span class="pb-0 font-weight-medium">
                        {{
                            new Intl.NumberFormat('de-DE', {
                                style: 'currency',
                                currency: quote.currency,
                                maximumFractionDigits: 2,
                            }).format(quote.totalCosts ? quote.totalCosts : 0)
                        }}
                    </span>
                </v-col>
            </v-row>
        </div>
        <v-dialog persistent :retain-focus="false" v-model="openFollowUpDialog">
            <v-card>
                <v-card-title class="text-h5">
                    <v-btn
                        class="mr-3"
                        depressed
                        color="primary"
                        icon
                        rounded
                        @click="closeFollowUp"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                    {{ quote.name }} - Follow Up
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-divider class="ml-4 mr-5" />
                <div class="px-2 py-2">
                    <FollowUp
                        :id="this.quote.id"
                        :quote="this.quote"
                        :settings="settings"
                    >
                    </FollowUp>
                </div>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { storage } from '@/services/firebase'

export default {
    name: 'CardQuote',
    props: {
        createHistory: Boolean,
        quote: Object,
        colorClass: String,
        color: String,
        clients: Array,
        contacts: Array,
        editAwardedQuote: Boolean,
        settings: Array,
    },
    components: {
        FollowUp: () => import('@/views/Quotes/QuoteFollowUp'),
    },
    data() {
        return {
            client: undefined,
            costs: {},
            companyId: JSON.parse(localStorage.getItem('company')),
            folderClientsPictures: 'clients_pictures',
            tempPicture: '',
            folderUsersPictures: 'users_pictures',
            collaborators: 1,
            openFollowUpDialog: false,
        }
    },

    computed: {
        ...mapState(['users']),
        ...mapGetters(['getClientQuote', 'getQuotes']),
    },
    watch: {
        client: function() {
            return this.quote.client
        },
        clients: async function() {
            await this.loadClientsAndContacts()
        },
    },
    async mounted() {
        if (this.quote.collaborators) {
            this.quote.dataCollaborators = []
            this.quote.collaborators.forEach(async element => {
                const dataUser = this.users.find(user => element == user.id)
                if (dataUser) this.quote.dataCollaborators.push(dataUser)
            })
        }
        if (this.quote.userId) {
            this.quote.mainContact = this.users.find(
                user => this.quote.userId == user.id
            )
        }
        if (this.quote.collaborators) {
            const tempData = this.quote.dataCollaborators.slice()
            tempData.push(this.quote.mainContact)
            this.$emit('loadCollaborator', tempData)
        } else {
            this.quote.collaborators = []
        }
        this.collaborators = this.quote.collaborators.filter(
            c => c != this.quote.userId
        ).length
        this.quote.users = this.users
        await this.loadClientsAndContacts()
    },
    methods: {
        openQuote() {
            this.$emit('openQuote', this.quote, this.costs)
        },
        ...mapActions(['addCostAction']),
        reOpenQuote: function() {
            if (this.quote.status == 'APPROVAL') {
                this.$emit('reOpenQuoteDialog', this.quote)
            } else {
                this.$emit('modificationRequestProcess', this.quote)
            }
        },
        quoteAwarded: function() {
            this.$emit('quoteAwarded', this.quote)
        },
        quoteApproval: function() {
            this.$emit('quoteApproval', this.quote)
        },
        closeDialog() {
            this.$emit('closeDialog')
        },
        archiveDialog() {
            this.$emit('archiveDialog', this.quote)
        },
        rejectedDialog() {
            this.$emit('rejectedDialog', this.quote)
        },
        routeQuote() {
            this.$emit('routeQuote', this.quote)
        },
        openFollowUp() {
            this.openFollowUpDialog = true
        },
        closeFollowUp() {
            this.openFollowUpDialog = false
        },
        async loadClientsAndContacts() {
            let client = undefined
            if (this.quote.clientId) {
                client = this.clients.find(
                    client => this.quote.clientId == client.id
                )
                if (client && client.picture && !client.profilePicURL) {
                    const path = `${this.companyId}/${this.folderClientsPictures}`
                    const storageRef = storage().ref(path)
                    const profilePicRef = storageRef.child(client.picture)
                    await profilePicRef
                        .getDownloadURL()
                        .then(URL => {
                            client.profilePicURL = URL
                            this.tempPicture = URL
                        })
                        .catch(error => {
                            client.profilePicURL = ''
                            return error && true
                        })
                } else {
                    if (client) this.tempPicture = client.profilePicURL
                }
            }
            if (this.quote.contacts) {
                this.quote.dataContacts = []
                this.quote.contacts.forEach(async element => {
                    const dataContact = this.contacts.find(
                        contact => element == contact.id
                    )
                    if (
                        dataContact &&
                        dataContact.picture &&
                        !dataContact.profilePicURL
                    ) {
                        const path = `${this.companyId}/${this.folderClientsPictures}`
                        const storageRef = storage().ref(path)
                        const profilePicRef = storageRef.child(
                            dataContact.picture
                        )
                        await profilePicRef
                            .getDownloadURL()
                            .then(url => {
                                dataContact.profilePicURL = url
                            })
                            .catch(error => {
                                dataContact.profilePicURL = ''
                                return error && true
                            })
                    }
                    this.quote.dataContacts.push(dataContact)
                })
            }
            this.quote.clients = this.clients
            this.quote.client = client
            this.client = client
        },
        formatDate(seconds, nanoseconds) {
            const date = new Date(seconds * 1000 + nanoseconds / 1e6)

            const options = { month: 'short', day: '2-digit', year: 'numeric' }
            const formattedDate = date.toLocaleDateString('en-US', options)
            const formattedTime = date.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
            })

            return `${formattedDate.replace(
                /, /g,
                '/'
            )} ${formattedTime.toLowerCase()}`
        },
    },
}
</script>
<style scoped>
.borderRounded {
    border-radius: 15px;
}
</style>
